import React, { useState, useEffect } from "react";

const Timer = ({ minutes }) => {
  const [min, setMin] = useState(Number(Math.floor(minutes / 60)));
  const [sec, setSec] = useState(Number(Math.floor(minutes % 60)));

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(myInterval);
        } else {
          setMin(min - 1);
          setSec(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [sec]);

  return (
    <div className="timers">
      <div className="text">min. left</div>
      <div className="time">
        <span>{min}</span>:
        <span>{sec < 10 ? <>{"0" + sec}</> : <>{sec}</>}</span>
      </div>
    </div>
  );
};

export default Timer;
