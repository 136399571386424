import React from "react";
import { Navigate } from "react-router-dom";
import async from "./components/Async";

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";

// Page components
import ResourcesDetails from "./pages/pages/InvoiceDetails";
import ResourcesList from "./pages/pages/InvoiceList";
import EndpointsServers from "./pages/pages/EndpointsServers";
import ApplicationsControl from "./pages/pages/ApplicationsControl";
import DataAccess from "./pages/pages/DataAccess";

import ApplicationsList from "./pages/pages/ApplicationsList";
import Policy from "./pages/pages/Policy";

import Pricing from "./pages/pages/Pricing";
import Projects from "./pages/pages/Projects";
import AccessManagement from "./pages/pages/AccessManagement";
import GetStart from "./pages/pages/GetStart";

// Documentation
import Changelog from "./pages/docs/Changelog";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";
import ProfileApplicationsControl from "./pages/pages/ProfileApplicationsControl";
import ProfileDataAcces from "./pages/pages/ProfileDataAcces";
import ProfilePrivilegeManagement from "./pages/pages/ProfilePrivilegeManagement";
import Endpoints from "./pages/pages/Endpoints";
import ProfileResources from "./pages/pages/ProfileResources";
import Account from "./pages/pages/Account";
import TenantManagement from "./pages/pages/TenantManagement";
import Downloads from "./pages/pages/Downloads";
import EndpointsResources from "./pages/pages/EndpointsResources";
import Profiles from "./pages/pages/Profiles";
import SessionsAndProcesses from "./pages/pages/SessionsAndProcesses";
import Incidents from "./pages/pages/Incidents";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));

// Icon components
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

const usersLoginStatus = localStorage.getItem("firstUserLogin");

setTimeout(() => {
  console.log("usersLoginStatus in routes", usersLoginStatus);
}, 1000);

const routes = [
  {
    // path: "/",
    // element: <Navigate to="/getstart" />,
    element: <DashboardLayout />,
    children: [
      {
        path: "getstart",
        element: <GetStart />,
      },
    ],
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "default",
        element: <Default />,
      },
      {
        path: "analytics",
        element: <Analytics />,
      },
    ],
  },
  {
    path: "policyProfiles",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Profiles />,
      },
      {
        path: "resources",
        element: <ProfileResources />,
      },
    ],
  },
  {
    path: "pricing",
    element: <DashboardLayout children={<Pricing />} />,
  },
  {
    path: "management",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <AccessManagement />,
      },
    ],
  },
  {
    path: "projects",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Projects />,
      },
    ],
  },
  {
    path: "tenant-management",
    element: <DashboardLayout children={<TenantManagement />} />,
  },
  {
    path: "incidents",
    element: <DashboardLayout children={<Incidents />} />,
  },
  {
    path: "endpoints",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Endpoints />,
      },
      {
        path: "sessions",
        element: <SessionsAndProcesses />,
      },
      {
        path: "resources",
        element: <EndpointsResources />,
      },
    ],
  },

  {
    path: "account",
    element: <DashboardLayout children={<Account />} />,
  },
  {
    path: "tasks",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Tasks />,
      },
    ],
  },
  {
    path: "downloads",
    element: <DashboardLayout children={<Downloads />} />,
  },
  {
    path: "calendar",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Calendar />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "getstart",
        element: <GetStart />,
      },
    ],
  },
  {
    path: "changelog",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Changelog />,
      },
    ],
  },
  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
