import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";

import PolicyModal from "../components/PolicyModal";

const Policy = () => {
  const [open, setOpen] = useState(false);
  const [policy, setPolicy] = useState(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    setPolicy(undefined);
  }, []);

  return (
    <>
      <PolicyModal open={open} handleClose={handleClose} />
      <section className="policySection">
        <section className="addNewPolicyButtonSection">
          <Button
            variant="outlined"
            className="newPolicyButton"
            onClick={handleOpen}
          >
            <AddCircleOutlineIcon /> Create Policy Profile
          </Button>
        </section>
        <section className="mainPolicyList">
          {policy !== undefined ? <div></div> : <p>No policy</p>}
        </section>
      </section>
    </>
  );
};

export default Policy;
