import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import axios from "axios";

import {
  Avatar,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  Grid,
  TextField as MuiTextField,
  Typography,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import KeyIcon from "@mui/icons-material/Key";
import { spacing } from "@mui/system";
import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";
import Link from "react-router-dom"

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const FormControl = styled(MuiFormControl)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);
const KeyIconStyled = styled(KeyIcon)(spacing);

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function Account() {
  const userEmail = useUserStore((state) => state.user.email);
  const userData = useUserStore((state) => state.user);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);
  const setisCachingEnabled = useUserStore(
    (state) => state.setisCachingEnabled
  );

  const [accountInfo, setAccountInfo] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${NGROK}/api/account?email=${userData.email}`
      );
      setAccountInfo(response.data);
    };
    fetchData();
  }, []);
  console.log(accountInfo, "accountInfo");
  return (
    <>
      <Helmet title="Settings" />

      <Typography variant="h3" gutterBottom display="inline">
        Account
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Public
            userData={userData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <Private
            userData={userData}
            accountInfo={accountInfo}
            setAccountInfo={setAccountInfo}
          />
          <FormControlLabel
            sx={{ fontSize: "20px" }}
            control={
              <Switch
                onChange={(e) => setisCachingEnabled(e.target.checked)}
                checked={isCachingEnabled}
              />
              
            }
            label="Toggle caching"
          />
          <a href={"/README.md"} target="_blank" rel="noreferrer">GO TO README</a>
        </Grid>
      </Grid>
    </>
  );
}

function Public({ accountInfo, setAccountInfo, userData }) {
  const { username, biography, id } = accountInfo;

  const updateData = async () => {
    const isUserNameUnique = await axios.get(
      `${NGROK}/api/account/check-username?username=${username}`
    );

    if (isUserNameUnique) {
      axios.put(`${NGROK}/api/account/${id}/settings/public-info`, {
        accountId: id,
        biography,
        username,
      });
    } else console.log("user is not unique");
  };

  console.log(userData, "userdata");
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Public info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={8}>
            <TextField
              value={username || ""}
              onChange={(e) =>
                setAccountInfo({ ...accountInfo, username: e.target.value })
              }
              id="username"
              label="User Name"
              variant="outlined"
              fullWidth
              my={2}
            />

            <FormControl fullWidth my={2} variant="outlined">
              <TextField
                value={biography || ""}
                onChange={(e) =>
                  setAccountInfo({ ...accountInfo, biography: e.target.value })
                }
                label="Biography"
                id="biography"
                multiline={true}
                rows={3}
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item md={4}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload
                </Button>

                <Typography variant="caption" display="block" gutterBottom>
                  For best results, use an image at least 128px by 128px in .jpg
                  format
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
        </Grid>

        <Button onClick={updateData} variant="contained" color="primary">
          Save changes
        </Button>
      </CardContent>
    </Card>
  );
}

function Private({ accountInfo, setAccountInfo }) {
  const {
    id,
    firstName,
    lastName,
    email,
    emailForUpdate,
    city,
    apartment,
    state,
    zip,
    userData,
  } = accountInfo;

  const updateData = () => {
    axios.put(`${NGROK}/api/account/${id}/settings/private-info`, {
      accountId: id,
      apartment,
      city,
      firstName,
      lastName,
      state,
      zip,
    });
  };

  const handleChange = (e) => {
    setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
  };
  console.log(accountInfo, "accountInfo");

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Private info
        </Typography>

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              value={firstName || ""}
              name={"firstName"}
              onChange={(e) => handleChange(e)}
              id="first-name"
              label="First Name"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              name={"lastName"}
              value={lastName || ""}
              onChange={(e) => handleChange(e)}
              id="last-name"
              label="Last Name"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>

        <TextField
          name={"email"}
          value={email || ""}
          onChange={(e) => handleChange(e)}
          id="email"
          label="Email"
          variant="outlined"
          type="email"
          fullWidth
          my={2}
        />
        {/* 
        <TextField
          value={setp.username}
              onChange={(e) => setPublicInfo({...publicInfo, username: e.target.value})}
          id="address"
          label="Address"
          variant="outlined"
          fullWidth
          my={2}
        /> */}

        <TextField
          name={"apartment"}
          value={apartment || ""}
          onChange={(e) => handleChange(e)}
          id="address2"
          label="Apartment"
          variant="outlined"
          fullWidth
          my={2}
        />

        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              name={"city"}
              value={city || ""}
              onChange={(e) => handleChange(e)}
              id="city"
              label="City"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              name={"state"}
              value={state || ""}
              onChange={(e) => handleChange(e)}
              id="state"
              label="State"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              name={"zip"}
              value={zip || ""}
              onChange={(e) => handleChange(e)}
              id="zip"
              label="ZIP"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>

        <Button onClick={updateData} variant="contained" color="primary" mt={3}>
          Save changes
        </Button>
      </CardContent>
    </Card>
  );
}

export default Account;
