import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../APIs";

import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";

import DeviceInfoTab from "../components/DeviceInfoTab";
import DomainTab from "../components/DomainTab";

import useUserStore from "../../services/userStore";

import "./pages.scss";

const EndpointsServers = () => {
  const [showDeviceTab, setShowDeviceTab] = useState("0");
  const [groupList] = useState(undefined);
  const [deviceList, setDeviceList] = useState();
  const [usersList, setUsersList] = useState(undefined);
  const [activeDevice, setActiveDevice] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [selectedUserId, setSelectedUserId] = useState();
  const [computerError, setComputerError] = useState(false);
  const [flag, setFlag] = useState("domain");

  const userData = useUserStore((state) => state.user);

  useEffect(() => {
    setUsersList([])
    axios
      .get(`${NGROK}/api/${userData.domainName}/computers`)
      .then((res) => {
        console.log("computers", res.data);
        if (res.data) {
          setDeviceList(res.data);
          setSelectedDevice(res.data[activeDevice]);
          setComputerError(false);
        }
      })
      .catch((error) => {
        if (error) setComputerError(true);
        console.log("computers error", error);
      });
    if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
      axios
        .get(`${NGROK}/api/${userData.domainName}/users`)
        .then((res) => {
          console.log("domain users", res.data);

          if (res.data) {
            setUsersList(res.data);
            setComputerError(false);
          }
        })
        .catch((error) => {
          if (error) setComputerError(true);
          console.log("domain users error", error);
        });
    } else if (userData.role === "TENANT_USER") {
      axios
        .get(
          `${NGROK}/api/${userData.domainName}/users/current?email=${userData.email}`
        )
        .then((res) => {
          console.log("domain users with  tenant user", res.data);

          if (res.data) {
            setUsersList(res.data);
            setComputerError(false);
          }
        })
        .catch((error) => {
          if (error) setComputerError(true);
          console.log("domain users error", error);
        });
    }
  }, [activeDevice, userData]);

  console.log("selectedUserId in main page", selectedUserId);

  return (
    <section className="endpointServersPages">
      <div className="leftTabSide">
        <TreeView
          className="treeViewList"
          aria-label="domain system navigator"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            minHeight: 240,
            maxHeight: 650,
            flexGrow: 1,
            maxWidth: 400,
            overflowY: "auto",
          }}
        >
          <TreeItem
            nodeId="1"
            label="Domain ${userData.domainName}"
            className="parentNode"
            onClick={() => {
              setShowDeviceTab("2");
              setFlag("domain");
            }}
          >
            {deviceList?.map((device, i) => (
              <TreeItem
                className="childNode computerName"
                key={i + device.dNSHostName}
                nodeId={`${i + 2}`}
                label={device.cn !== null ? device.cn : device.dNSHostName}
                title={device.cn !== null ? device.cn : device.dNSHostName}
                onClick={() => {
                  setShowDeviceTab("1");
                  setFlag("computer");
                  setActiveDevice(device.id);
                }}
              />
            ))}
          </TreeItem>
        </TreeView>
      </div>
      <div className="rightTabSide">
        {computerError ? (
          <p className="textInRightSide">Wait on the response from server...</p>
        ) : (
          <>
            {showDeviceTab === "0" ? (
              <p className="textInRightSide">
                Select the Domain to see information about.
              </p>
            ) : null}
            {showDeviceTab === "1" ? (
              <DeviceInfoTab
                deviceList={deviceList}
                selectedDevice={selectedDevice}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                flag={flag}
                activeComputer={activeDevice}
                userData={userData}
              />
            ) : null}
            {showDeviceTab === "2" ? (
              <>
                {usersList.length === 0 ? (
                  <p style={{ fontSize: "24px" }}>
                    You have no access to Domain
                  </p>
                ) : (
                  <DomainTab
                    users={usersList}
                    groups={groupList}
                    selectedUserId={selectedUserId}
                    setSelectedUserId={setSelectedUserId}
                    flag={flag}
                    activeComputer={activeDevice}
                  />
                )}
              </>
            ) : null}
          </>
        )}
      </div>
    </section>
  );
};

export default EndpointsServers;
