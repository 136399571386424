import React, { useState, useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import UserService from "./services/UserServices";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import "./i18n";
import createTheme from "./theme";
import routes from "./routes";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import { AuthProvider } from "./contexts/JWTContext";

import useUserStore from "./services/userStore";
import ModalProvider from "./pages/components/ModalProvider/ModalProvider";
import { getLastSession } from "./services/Helpers";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const App = () => {
  const content = useRoutes(routes);
  const fetchData = useUserStore((state) => state.fetch);
  const userData = useUserStore((state) => state.user);
  const setLastSession = useUserStore((state) => state.setLastSession);

  const navigate = useNavigate();

  console.log("user data from zustand", userData);

  const { theme } = useTheme();

  console.log("getUser", UserService._kc().tokenParsed);

  const checkFirstLoginforUser = async (user) => {
    console.log(user, "user")
    localStorage.removeItem(`get-all-domains?email=${userData.email}-30min`);
    if (
      user.tenantNames &&
      user.tenantNames.length
    ) {
      // setTenantName(user.tenantNames[0]);
      navigate("/endpoints");
    } else {
      navigate("/getstart");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (userData && userData.email) {
      checkFirstLoginforUser(userData);
      getLastSession(userData.email).then((session) => setLastSession(session))
    }
  }, [userData]);

  return (
    <>
      {userData.loading ? (
        <p>loading</p>
      ) : (
        <HelmetProvider>
          <Helmet
            titleTemplate="%s | White Swan Security"
            defaultTitle="White Swan Security"
          />
          <Provider store={store}>
            <ModalProvider>
              <StylesProvider jss={jss}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <StyledEngineProvider injectFirst>
                    <MuiThemeProvider theme={createTheme(theme)}>
                      <ThemeProvider theme={createTheme(theme)}>
                        <AuthProvider>{content}</AuthProvider>
                      </ThemeProvider>
                    </MuiThemeProvider>
                  </StyledEngineProvider>
                </LocalizationProvider>
              </StylesProvider>
            </ModalProvider>
          </Provider>
        </HelmetProvider>
      )}
    </>
  );
};

export default App;
