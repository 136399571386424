import { NGROK } from "../APIs";
import { myLocalStorage } from "../components/StorageHelper";
import axios from "axios";

export const postLastSession = async ({
  email = null,
  latestTenant = null,
  latestComputerId = null,
  latestUserId = null,
  resourceId = null,
  latestPublisherId = null,
  latestAppId = null,
  latestFolderId = null,
  latestGroupId = null,
  latestTenantMode = null,
  latestUsername = null
}) => {
  const response = await axios.post(
    `${NGROK}/api/latest-checking`,
    {
      email,
      latestTenant,
      latestComputerId,
      latestUserId,
      resourceId,
      latestPublisherId,
      latestAppId,
      latestFolderId,
      latestGroupId,
      latestTenantMode,
      latestUsername
    }
  );
  myLocalStorage.setItem(`${email}-lastSession`, response.data);
  return response.data;
};

export const getLastSession = async (userEmail) => {
  const lastSession = myLocalStorage.getItem(`${userEmail}-lastSession`);
  if (lastSession && Object.keys(lastSession)?.length) return lastSession;
  else {
    const response = await axios.get(
      `${NGROK}/api/latest-checking?email=${userEmail}`
    );
    myLocalStorage.setItem(`${userEmail}-lastSession`, response.data);
    return response.data;
  }
};
