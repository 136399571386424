import React, { useState } from "react";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Dots from "../../vendor/icons/3_dots.svg";
import GreenActive from "../../vendor/icons/greenActive.png";

import EndpointsUsersSearch from "../components/EndpointsUsersSearch";
import UserApplicationModalWindow from "../components/UserApplicationModalWindow";
import PolicyModal from "../components/PolicyModal";

const ApplicationTable = ({ tabIndex }) => {
  const [open, setOpen] = useState(false);
  const [userModal, setUserModal] = useState(null);

  const [openPolicy, setOpenPolicy] = useState(false);
  const handleOpenPolicy = () => setOpenPolicy(true);
  const handleClosePolicy = () => setOpenPolicy(false);

  const openModal = (data) => {
    setUserModal(data);
    setOpen(!open);
  };

  const [applicationData, setApplicationData] = useState([
    {
      name: "Zoom",
      discoveredOn: "11 Aug 2021 11:00 PM PST",
      status: "Active",
    },
    {
      name: "Zoom sharing host",
      discoveredOn: "11 Aug 2021 11:00 PM PST",
      status: "Active",
    },
    {
      name: "Sharing host",
      discoveredOn: "11 Aug 2021 11:00 PM PST",
      status: "Active",
    },
    {
      name: "Zoom host",
      discoveredOn: "11 Aug 2021 11:00 PM PST",
      status: "Active",
    },
    {
      name: "Zoom sharing",
      discoveredOn: "11 Aug 2021 11:00 PM PST",
      status: "Active",
    },
  ]);

  return (
    <TableContainer component={Paper} className="usersTableSettings">
      <PolicyModal open={openPolicy} handleClose={handleClosePolicy} />
      {userModal && (
        <UserApplicationModalWindow
          open={open}
          setOpen={setOpen}
          userModal={userModal}
          tabState={"application"}
        />
      )}
      <EndpointsUsersSearch
        applicationData={applicationData}
        setApplicationData={setApplicationData}
        handleOpenPolicy={handleOpenPolicy}
      />
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Application Name</TableCell>
            <TableCell>Discovered On</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {applicationData.map((application, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" className="userTableCell">
                {application.name}
              </TableCell>
              <TableCell className="userTableCell">
                {application.discoveredOn}
              </TableCell>
              <TableCell className="userTableCell">
                <div>
                  <img className="activeImage" src={GreenActive} alt="img" />
                  {application.status}
                </div>
              </TableCell>
              <TableCell className="userTableCell buttonSell">
                <button onClick={() => openModal(application)}>
                  <img src={Dots} alt="img" />
                </button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApplicationTable;
