import React, { useState, useEffect } from "react";
import UserService from "../../services/UserServices";
import { useNavigate } from "react-router-dom";

import useUserStore from "../../services/userStore";
import { postLastSession } from "../../services/Helpers";

const LogoutTimer = () => {
  const navigate = useNavigate();
  const lastSession = useUserStore((state) => state.lastSession);

  const [min, setMin] = useState(+localStorage.getItem("sessionTime"));
  const [sec, setSec] = useState(Number(0));
  const doLogOutState = useUserStore((state) => state.logOut);
  const setSessionMinutesLeft = useUserStore(
    (state) => state.setSessionMinutesLeft
  );

  useEffect(() => {
    let myInterval = setInterval(() => {
      const sessionTime = +localStorage.getItem("sessionTime");
      if (sessionTime !== min) setMin(sessionTime);
      if (sec > 0) {
        setSec(sec - 1);
      }
      if (sec === 0) {
        if (min === 0) {
          clearInterval(myInterval);
          if (UserService._kc()) {
            postLastSession({
              ...lastSession,
            })
              .then(() => navigate("/"))
              .then(() => doLogOutState());
          } else {
            console.log("No users in UserService");
          }
        } else {
          const sessionTime = +localStorage.getItem("sessionTime");
          setMin(sessionTime - 1);
          setSec(59);
          localStorage.setItem("sessionTime", sessionTime - 1);
          if (+min === 1) setSessionMinutesLeft(min);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [sec]);

  return (
    <>
      <span>{min}</span>:<span>{sec < 10 ? <>{"0" + sec}</> : <>{sec}</>}</span>
    </>
  );
};

export default LogoutTimer;
