import Keycloak from "keycloak-js";

let local_kc;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const userToken = localStorage.getItem("userToken");
const sessionTime = localStorage.getItem("sessionTime");

const setUpKc = (urlIn) => {
  if (!userToken) {
    local_kc = new Keycloak({
      url: urlIn,
      realm: "Whiteswan_Tenants",
      clientId: "otp-auth",
    });
  } else {
    local_kc = new Keycloak({
      url: urlIn,
      realm: "Whiteswan_Tenants",
      clientId: "otp-auth",
      token: userToken,
    });
  }
};

const _kc = () => {
  return local_kc;
};

const initKeycloak = async (onAuthenticatedCallback) => {
  console.log(local_kc, "_kc()");
  try {
    local_kc
      .init({
        onLoad: "login-required",

        onTokenExpired: () => {
          local_kc
            .updateToken(5)
            .success((refreshed) => {
              if (refreshed) {
                console.log("Token was successfully refreshed");
              } else {
                console.log("Token is still valid");
              }
            })
            .error(() => {
              console.error(
                "Failed to refresh the token, or the session has expired"
              );
            });
        },
      })
      .then((authenticated) => {
        if (authenticated) {
          console.log("user is Authenticated..!");
        }
        if (!authenticated) {
          console.log("user is not authenticated..!");
        }
        localStorage.setItem("userEmail", `${local_kc.tokenParsed.email}`);
        localStorage.setItem("token", `${local_kc.token}`);
        localStorage.setItem("refreshToken", `${local_kc.refreshToken}`);
        if (sessionTime)  localStorage.setItem("sessionTime", 30 - sessionTime);
        else if (!sessionTime) localStorage.setItem("sessionTime", 30);
        onAuthenticatedCallback();
      })
      .catch(console.error);
  } catch (error) {
    console.log(error);
  }
};

// const doLogin = _kc().login;

const doLogout = () => {
  localStorage.removeItem("userEmail");
  localStorage.removeItem("sessionTime");

  local_kc.logout();
};

const getToken = () => local_kc.token;

const isLoggedIn = () => !!local_kc.token;

// const updateToken = (successCallback) =>
//   _kc().updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => local_kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => local_kc.hasRealmRole(role));

const UserService = {
  setUpKc,
  initKeycloak,
  //   doLogin,
  doLogout,
  isLoggedIn,
  //   updateToken,
  getToken,
  getUsername,
  hasRole,
  _kc,
};

export default UserService;
