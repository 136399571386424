import React, { useState } from "react";
import axios from "axios";

import { NGROK } from "../../APIs";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Timer from "../components/Timer";
import useUserStore from "../../services/userStore";
import RadioAuthFilter from "../components/RadioAuthFilter";
import { MenuItem, Select, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const PrivilegeManagement = ({
  users,
  groups,
  getUserGroups,
  selectedTenantName,
  setGroups,
  showCount,
  setShowCount,
}) => {
  const resourcesDefaultTime = useUserStore(
    (state) => state.resourcesDefaultTime
  );
  const activeComputer = useUserStore((state) => state.activeComputer);

  const [filterGroupValue, setFilterGroupValue] = useState("Show All");
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackbarSettings;

  const handleSelectTime = (event, uId, gId, type) => {
    if (event.key === "Enter") {
      addUserToGroup(uId, gId, +event.target.value, type);
    }
  };

  const changeGroupStatus = (newGroup) => {
    const updatedGroups = groups.map((group) =>
      group.adComputerUser.id === newGroup.adComputerUser.id &&
      group.adComputerUserGroup.id === newGroup.adComputerUserGroup.id
        ? newGroup
        : group
    );

    setGroups(updatedGroups);
  };

  const addUserToGroup = async (group, groupStatus) => {
    const profileName = activeComputer.profile;
    const timeInSeconds = resourcesDefaultTime[profileName] * 60;

    const storageKey = `${selectedTenantName}/computer-user-groups/membership/${group.adComputerUser.id}-30min`;

    changeGroupStatus({ ...group, groupStatus });
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenantName}/computer-user-groups/membership?userId=${group.adComputerUser.id}&groupId=${group.adComputerUserGroup.id}&groupStatus=${groupStatus}&selectedTime=${timeInSeconds}`
      );
      if (
        groupStatus === "INHERITED" &&
        response.data.inheritedGroupStatus === null
      ) {
        setSnackbarSettings((prev) => ({ ...prev, open: true }));
        setTimeout(() => {
          setSnackbarSettings((prev) => ({ ...prev, open: false }));
        }, 4000);
      }
      localStorage.removeItem(storageKey);
      changeGroupStatus(response.data);
    } catch (error) {
      changeGroupStatus(group);
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterGroupValue(e.target.value);
  };

  const filteredGroups = groups?.filter((group) => {
    if (filterGroupValue === "Show All") return group;
    else if (filterGroupValue === "Show Yes only")
      return (
        group.groupStatus === "ENABLED" ||
        group.inheritedGroupStatus === "ENABLED"
      );
    else if (filterGroupValue === "Show No only")
      return (
        group.groupStatus === "DISABLED" ||
        group.inheritedGroupStatus === "DISABLED"
      );
    else if (filterGroupValue === "Show Dynamic only")
      return (
        group.groupStatus === "DYNAMIC" ||
        group.inheritedGroupStatus === "DYNAMIC"
      );
  });

  console.log(groups, "groups");
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() =>
          setSnackbarSettings({ ...snackbarSettings, open: false })
        }
        message="You can't make this resource inherited"
        key={vertical + horizontal}
      >
        <MuiAlert
          sx={{ fontSize: "18px", fontWeight: "500" }}
          severity="warning"
        >
          You can't make this resource inherited
        </MuiAlert>
      </Snackbar>
      {groups && groups[0] && groups[0].adComputerUserGroup ? (
        <TableContainer component={Paper} sx={{ height: "fit-content" }}>
          <Box display={"flex"} alignItems={"center"} padding={"15px"}>
            <RadioAuthFilter
              handleFilterResourceValue={handleFilterResourceValue}
            />
          </Box>
          <Table
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
            size="large"
          >
            <TableHead>
              <TableRow>
                <TableCell className="userName">Groups</TableCell>
                <TableCell className="userName">Membership</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredGroups?.slice(0, showCount).map((group) => (
                <TableRow
                  className="groupRow"
                  key={group.adComputerUserGroup.cn}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="groupRowName userName"
                    title={group.adComputerUserGroup.cn}
                  >
                    {group.adComputerUserGroup.cn}
                  </TableCell>
                  <TableCell>
                    <Stack direction={"row"} spacing={3}>
                      <Select
                        sx={{ minWidth: "100px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={group.groupStatus ? group.groupStatus : ""}
                        size="small"
                        onChange={(event) =>
                          addUserToGroup(group, event.target.value)
                        }
                      >
                        <MenuItem value={"ENABLED"}>YES</MenuItem>
                        <MenuItem value={"DISABLED"}>NO</MenuItem>
                        <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                        <MenuItem value={"INHERITED"}>
                          INHERITED{" "}
                          {group.inheritedGroupStatus === "ENABLED"
                            ? "(YES)"
                            : group.inheritedGroupStatus === "DISABLED"
                            ? "(NO)"
                            : "(DYNAMIC)"}
                        </MenuItem>
                      </Select>
                      {group.groupStatus === "DYNAMIC" &&
                      group.remainingTime !== 0 ? (
                        <Stack spacing={1} sx={{ width: 150 }}>
                          <Timer minutes={group.remainingTime} />
                        </Stack>
                      ) : null}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {groups?.length > showCount ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : groups === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any groups.
          </p>
        </Box>
      )}
    </>
  );
};

export default PrivilegeManagement;
