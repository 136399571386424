import React from "react";

import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";

const EndpointsUsersSearch = ({
  applicationData,
  setApplicationData,
  handleOpenPolicy,
}) => {
  const searchUser = (e) => {
    console.log("e", e.target.value);
    let inputValue = e.target.value;
    console.log("applicatyions ", applicationData);
    if (inputValue === "") {
      setApplicationData(applicationData);
    } else {
      let filtered = applicationData.filter((device) =>
        device.name.toLowerCase().includes(inputValue.toLowerCase())
      );
      console.log("filtered", filtered);
      setApplicationData(filtered);
    }
  };

  return (
    <section className="tableSearchSection">
      <div className="searchWrapper">
        <div className="searchBar">
          <input
            className="serachInput"
            type="text"
            placeholder="Search CVEs"
            name="serachInput"
            onChange={(e) => searchUser(e)}
          />
          <SearchIcon className="searchIcon" />
        </div>
      </div>
      <div className="buttonsWrapper">
        <Button variant="outlined" className="addPolicies">
          ADD POLICIES
        </Button>
        <Button
          className="createPolicies"
          variant="contained"
          onClick={() => handleOpenPolicy(true)}
        >
          CREATE POLICIES
        </Button>
        {/* <Tooltip title="Update">
          <button className="mainButtons">
            <CachedIcon className="iconsColor" />
          </button>
        </Tooltip>
        <Tooltip title="More actions">
          <button className="mainButtons">
            <img className="iconsColor" src={Dashes} alt="img" />
          </button>
        </Tooltip> */}
      </div>
    </section>
  );
};

export default EndpointsUsersSearch;
