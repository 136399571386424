import React, { useState, useEffect } from "react";
import axios from "axios";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import { alpha } from "@mui/material/styles";
import useUserStore from "../../../services/userStore";
import { NGROK } from "../../../APIs";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 378px;
`;

function LineChart({ theme }) {
  const lastSession = useUserStore((state) => state.lastSession);

  const [privilegedUsersNumbers, setPrivilegedUsersNumbers] = useState([]);

  useEffect(() => {
    const getPrivilegedUsersCount = async () => {
      const res = await axios.get(
        `${NGROK}/api/statistics/groups/count?tenantName=${lastSession.latestTenant}`
      );
      const privilegeNumbers = res.data?.map((el) => el.groupsAmount);
      setPrivilegedUsersNumbers(privilegeNumbers);
    };
    getPrivilegedUsersCount();
  }, [lastSession.latestTenant]);

  const data = (canvas) => {
    const ctx = canvas.getContext("2d");

    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, alpha(theme.palette.secondary.main, 0.0875));
    gradient.addColorStop(1, "rgba(0, 0, 0, 0)");

    return {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      datasets: [
        {
          label: "Sales ($)",
          fill: true,
          backgroundColor: gradient,
          borderColor: theme.palette.secondary.main,
          tension: 0.3,
          data: privilegedUsersNumbers,
        },
      ],
    };
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0,0,0,0.0)",
        },
      },
      y: {
        grid: {
          color: "rgba(0,0,0,0.0375)",
          fontColor: "#fff",
        },
      },
    },
  };

  console.log(privilegedUsersNumbers, "privilegedUsersNumbers");

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Privilege creep over time"
      />
      <CardContent>
        <ChartWrapper>
          <Chart type="line" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
}
export default withTheme(LineChart);
