import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./pages.scss";

import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
} from "@mui/material";

import useUserStore from "../../services/userStore";
import { NGROK } from "../../APIs";
import { myLocalStorage } from "../../components/StorageHelper";
import SnackBarButton from "../components/SnackBarButton";
import CountdownTimer from "../components/CountdownTimer";

const TenantManagement = () => {
  const navigate = useNavigate();

  const userData = useUserStore((state) => state.user);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);

  const [tenantList, setTenantList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedTenantName, setSelectedTenantName] = useState(null);
  const [tenantInfo, setTenantInfo] = useState(null);
  const [apiKeyLoading, setApiKeyLoading] = useState({tenant: null, loading: false});

  const deleteTenant = (tenant_name) => {
    const response = axios.delete(
      `${NGROK}/api/delete-tenant?name=${tenant_name}`
    );

    setTenantList((prev) => {
      return prev.filter((tenant) => tenant.tenantName !== tenant_name);
    });
    localStorage.removeItem(`get-all-domains?email=${userData.email}-30min`);
  };

  const deleteApiKey = async (tenant) => {
      setApiKeyLoading({tenant: tenant.tenantName, loading: true});
    const response = await axios.delete(
      `${NGROK}/api/${tenant.tenantName}/tenant-admin/delete-api-key/${tenant.apiKey.key}?email=${tenant.email}`
    );

    localStorage.removeItem(`get-all-domains?email=${userData.email}-30min`);

    setTenantList(
      tenantList.map((el) => {
        if (el.domainName === tenant.domainName) {
          return {
            ...el,
            apiKey: { key: "", expiredAt: "" },
          };
        } else {
          return el;
        }
      })
    );
      setApiKeyLoading({tenant: tenant.tenantName, loading: false});
  };

  const generateApiKey = async (tenant) => {
    if (tenant) {
      setApiKeyLoading({tenant: tenant.tenantName, loading: true});
      const res = await axios.get(
        `${NGROK}/api/${tenant.tenantName}/tenant-admin/get-api-key?email=${tenant.email}`
      );

      localStorage.removeItem(`get-all-domains?email=${userData.email}-30min`);

      setTenantList(
        tenantList.map((el) => {
          if (el.domainName === tenant.domainName) {
            return {
              ...el,
              apiKey: { key: res.data.key, expiredAt: res.data.expiredAt },
            };
          } else {
            return el;
          }
        })
      );
      setApiKeyLoading({tenant: tenant.tenantName, loading: false});
    }
  };

  const findTenant = (e) => {
    setSearchValue(e.target.value);
  };

  const getTenantInfo = async (tenant, e) => {
    if (e.target.tagName === "TD") {
      const response = await axios.get(
        `${NGROK}/api/${tenant.tenantName}/get-domain-info?email=${tenant.email}`
      );
      setTenantInfo(response.data);
      setSelectedTenantName(tenant.tenantName);
      console.log(response.data, "tenantInfo");
    }
  };

  useEffect(() => {
    const storageKey = `get-all-domains?email=${userData.email}-30min`;

    const storageDomains = myLocalStorage.getItem(storageKey);

    if (storageDomains && storageDomains.length && isCachingEnabled) {
      setTenantList([]);
      setTimeout(() => {
        setTenantList(storageDomains);
      }, 500);
    } else {
      const fetchTenants = async () => {
        const response = await axios.get(
          `${NGROK}/api/get-all-domains?email=${userData.email}`
        );
        setTenantList(response.data);
        myLocalStorage.setItem(storageKey, response.data, 30);
      };
      fetchTenants();
    }
  }, []);
  console.log(tenantList, "lsit");
  return (
    <>
      <Stack
        spacing={2}
        direction={"row"}
        display={"flex"}
        alignItems={"center"}
      >
        <TextField
          sx={{ width: "30%", margin: "10px 0 10px 0" }}
          label="Search the Tenant..."
          id="searchForTenant"
          onChange={findTenant}
        />
        <Button
          onClick={() => navigate("/getstart")}
          sx={{ height: "55px" }}
          size="large"
          variant={"outlined"}
        >
          Create new tenant
        </Button>
      </Stack>
      <Stack direction={"row"} spacing={2}>
        <TableContainer
          sx={{ minWidth: "70%", maxWidth: "80%" }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead
              sx={{
                backgroundColor: "#233044",
              }}
            >
              <TableRow>
                <TableCell sx={{ color: "white", fontSize: "16px" }}>
                  Tenant
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Number of computers
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Number of users
                </TableCell>
                <TableCell
                  colSpan={1}
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Api key
                </TableCell>
                <TableCell
                  colSpan={2}
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Api key Management
                </TableCell>
                <TableCell
                  sx={{ color: "white", fontSize: "16px" }}
                  align="center"
                >
                  Tenant Management
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tenantList
                .filter((tenant) => {
                  if (searchValue === "") {
                    return tenant;
                  } else if (
                    tenant.tenantName
                      .toLowerCase()
                      .includes(searchValue.toLowerCase())
                  ) {
                    return tenant;
                  }
                })
                .map((tenant) => (
                  <TableRow
                    key={tenant.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={(e) => getTenantInfo(tenant, e)}
                    className={
                      tenant.tenantName === selectedTenantName
                        ? "tenantRow activeTenantRow"
                        : "tenantRow"
                    }
                  >
                    <TableCell sx={{ fontSize: "16px" }}>
                      {tenant.tenantName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {tenant.numberOfComputers}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      {tenant.numberOfUsers}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <SnackBarButton
                        selectedTenantName={selectedTenantName}
                        generateApiKey={generateApiKey}
                        tenant={tenant}
                        apiKey={tenant.apiKey.key}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <CountdownTimer
                        setTenantList={setTenantList}
                        tenantList={tenantList}
                        selectedTenantName={selectedTenantName}
                        deleteApiKey={deleteApiKey}
                        tenant={tenant}
                        apiKeyLoading={apiKeyLoading}
                      />
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Button
                        disabled={!tenant.apiKey.key}
                        onClick={() => deleteApiKey(tenant)}
                        sx={
                          tenant.tenantName === selectedTenantName
                            ? { color: "white" }
                            : {}
                        }
                        variant="outlined"
                        color={"error"}
                      >
                        Delete API Key
                      </Button>
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Button
                        sx={
                          tenant.tenantName === selectedTenantName
                            ? { color: "white" }
                            : {}
                        }
                        onClick={() => deleteTenant(tenant.tenantName)}
                        variant="outlined"
                        color={"error"}
                      >
                        Delete Tenant
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {selectedTenantName ? (
          <TableContainer
            component={Paper}
            sx={{
              backgroundColor: "white",
              maxHeight: "725px",
              position: "sticky",
              top: 0,
              minWidth: "300px",
              overflow: "hidden",
            }}
          >
            <Table aria-label="table">
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell
                    colSpan={2}
                    align="center"
                    sx={{ color: "white", fontSize: "16px" }}
                  >
                    Tenant Info
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(tenantInfo).map(
                  ([key, value]) =>
                    key !== "domainName" &&
                    key !== "tenantName" &&
                    key !== "bindPassword" &&
                    key !== "domainPasswordProtect" &&
                    key !== "domainPassword" && (
                      <TableRow>
                        <TableCell sx={{ fontSize: "16px" }}>{key}:</TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          {value}
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </Stack>
    </>
  );
};

export default TenantManagement;
