import { Alert, Button, Snackbar } from "@mui/material";
import React, { useState, useEffect } from "react";
import useUserStore from "../../../services/userStore";

const ModalProvider = ({ children }) => {
  const min = useUserStore((state) => state.sessionMinutesLeft);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });

  useEffect(() => {
    if (+min === 1) {
      setState((prev) => {
        return { ...prev, open: true };
      });
      setTimeout(() => {
        setState((prev) => {
          return { ...prev, open: false };
        });
      }, 60000);
    }
  }, [min]);

  const { vertical, horizontal, open } = state;
  
  const extendSession = () => {
    const storageMinLeft = +localStorage.getItem("sessionTime")
    localStorage.setItem("sessionTime", storageMinLeft + 30)
     setState((prev) => {
          return { ...prev, open: false };
        });
  }

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          Less than a minute left to sign out. Please, log in again to extend
          session for another 30 minutes or click{" "}
          <Button onClick={extendSession} sx={{ display: "inline" }} variant="text" size="small">
            Here
          </Button>{" "}
          to extend session for 10 minutes.
        </Alert>
      </Snackbar>
      {children}
    </>
  );
};

export default ModalProvider;
