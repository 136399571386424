import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../APIs";

import "./pages.scss";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { myLocalStorage } from "../../components/StorageHelper";
import useUserStore from "../../services/userStore";
import { useNavigate } from "react-router-dom";

const Profiles = () => {
  const navigate = useNavigate();
  const [storageProfiles, setStorageProfiles] = useState([]);
  const [tenantsList, setTenantsList] = useState([]);
  const [storageTenants, setStorageTenants] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(undefined);
  const [profilesList, setProfilesList] = useState([]);
  const [selectedError, setSelectedError] = useState("");
  const setLastSession = useUserStore((state) => state.setLastSession);
  const lastSession = useUserStore((state) => state.lastSession);

  const setDefaultTenant = useUserStore((state) => state.setDefaultTenant);
  const setSelectedProfile = useUserStore((state) => state.setSelectedProfile);

  const userData = useUserStore((state) => state.user);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);
  const setResourcesDefaultTime = useUserStore((state) => state.setResourcesDefaultTime);
  const resourcesDefaultTime = useUserStore((state) => state.resourcesDefaultTime);

  const { latestTenant } = lastSession;

  const fetchTenantsData = async () => {
    const response = await axios.get(
      `${NGROK}/api/get-all-domains?email=${userData.email}`
    );
    console.log(response.data, "fetchTenantsData");
    setTenantsList(response.data);

    const storageKey = `api/get-all-domains?email=${userData.email}-30min`;
    myLocalStorage.setItem(storageKey, response.data, 30);
    setStorageTenants(myLocalStorage.getItem(storageKey));
  };

  const fetchTenantProfiles = async (tenantName) => {
    setProfilesList(undefined);
    try {
      const response = await axios.get(
        `${NGROK}/api/profiles/inherited?tenantName=${tenantName}`
      );
      setProfilesList(response.data);
    } catch (error) {
      console.log(error, "error");
    }

    const storageKey = `api/profiles-30min`;
    myLocalStorage.setItem(storageKey, profilesList, 30);
    setStorageProfiles(myLocalStorage.getItem(storageKey));
  };

  const updateTenantOperationMode = async (event, tenant) => {
    const newMode = event.target.value;
    const updatedTenant = { ...tenant, mode: newMode };

    setSelectedTenant(updatedTenant);

    try {
      await axios.put(`${NGROK}/api/${tenant.tenantName}/operation-mode`, {
        mode: newMode,
      });
    } catch (error) {
      setSelectedError("Error during request to server");
    }
  };

  const updateProfileOperatingMode = async (event, profile) => {
    const newMode = event.target.value;
    const updatedprofile = { ...profile, mode: newMode };
    const updatedProfiles = profilesList.map((element, index) =>
      element.id === updatedprofile.id
        ? (profilesList[index] = updatedprofile)
        : element
    );
    setProfilesList(updatedProfiles);

    try {
      if (newMode === "INHERITED_FROM_TENANT") {
        const response = await axios.put(
          `${NGROK}/api/profiles/${profile.id}/operation-mode`,
          {
            inheritedByTenantName: selectedTenant.tenantName,
            mode: "INHERITED_FROM_TENANT",
          }
        );
      } else {
        const response = await axios.put(
          `${NGROK}/api/profiles/${profile.id}/operation-mode`,
          { mode: newMode }
        );
      }
    } catch (error) {
      setSelectedError("Error during request to server");
    }
  };

  useEffect(() => {
    if (storageTenants && storageTenants.length && isCachingEnabled) {
      setTenantsList([]);
      setTimeout(() => {
        setTenantsList(storageTenants);
      }, 500);
    } else {
      fetchTenantsData();
    }
  }, []);

  useEffect(() => {
    if (tenantsList.length === 1) return setSelectedTenant(tenantsList[0]);

    if (latestTenant && tenantsList.length) {
      const tenant = tenantsList.find(
        (tenant) => tenant.tenantName === latestTenant
      );

      if (tenant !== undefined) setSelectedTenant(tenant);
      else setSelectedTenant(tenantsList[0]);
    } else if (!latestTenant && tenantsList.length)
      setSelectedTenant(tenantsList[0]);
  }, [tenantsList]);

  useEffect(() => {
    if (selectedTenant) {
      fetchTenantProfiles(selectedTenant.tenantName);
      setLastSession({
        ...lastSession,
        email: userData.email,
        latestTenant: selectedTenant.tenantName,
        latestTenantMode: selectedTenant.mode,
      });
    }
  }, [selectedTenant]);

  console.log(profilesList, "profilesList");
   console.log(resourcesDefaultTime, "resourcesDefaultTime");

  return (
    <Stack direction={"column"} spacing={4}>
      {tenantsList?.length ? (
        <Stack direction={"row"} spacing={4}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            value={
              selectedTenant !== undefined ? selectedTenant.tenantName : " "
            }
            options={tenantsList.map((tenant) => tenant.tenantName)}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Tenant List" />
            )}
            onChange={(e, value) => {
              if (!value) {
                setDefaultTenant({});
                setSelectedTenant(undefined);
                setProfilesList([]);
              }
              const tenant = tenantsList.find(
                (tenant) => tenant.tenantName === value
              );
              if (tenant !== undefined) {
                setDefaultTenant(tenant);
                setSelectedTenant(tenant);
              }
            }}
          />
          {selectedTenant ? (
            <Stack direction={"row"} spacing={4}>
              <FormControl sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel id="demo-simple-select-label">
                  Operation Mode
                </InputLabel>
                <Select
                  sx={{ minWidth: "100px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Operation Mode"
                  value={selectedTenant.mode ? selectedTenant.mode : " "}
                  size="medium"
                  onChange={(event) =>
                    updateTenantOperationMode(event, selectedTenant)
                  }
                >
                  <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                  <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                  <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                  );
                </Select>
              </FormControl>
            </Stack>
          ) : null}
        </Stack>
      ) : null}
      <TableContainer
        sx={{ height: "fit-content", minWidth: "400px", width: "fit-content" }}
        component={Paper}
      >
        <Table size="large">
          <TableHead>
            <TableRow>
              <TableCell className="userName">Profile</TableCell>
              <TableCell className="userName">Mode</TableCell>
              <TableCell className="userName">Default time</TableCell>
              <TableCell className="userName">Resources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profilesList?.length && tenantsList?.length ? (
              profilesList.map((profile) => (
                <TableRow
                  key={profile.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    sx={{ width: "100px" }}
                    component="th"
                    scope="row"
                    title={profile.name}
                  >
                    <Typography fontSize={14}>{profile.name}</Typography>
                  </TableCell>
                  <TableCell>
                    <Select
                      sx={{ minWidth: "80px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={profile.mode ? profile.mode : " "}
                      size="small"
                      onChange={(event) =>
                        updateProfileOperatingMode(event, profile)
                      }
                    >
                      <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                      <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                      <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                      <MenuItem value={"INHERITED_FROM_TENANT"}>
                        INHERITED {`(${selectedTenant.mode})`}
                      </MenuItem>
                      );
                    </Select>
                  </TableCell>
                  <TableCell>
                    <Select
                      sx={{ minWidth: "40px" }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={
                        profile.name === "windows_critical"
                          ? resourcesDefaultTime.windows_critical
                          : profile.name === "windows_normal"
                          ? resourcesDefaultTime.windows_normal
                          : profile.name === "windows_secure"
                          ? resourcesDefaultTime.windows_secure
                          : resourcesDefaultTime.windows_original
                      }
                      onChange={(e) => {
                        setResourcesDefaultTime(profile.name, e.target.value)
                      }}
                      size="small"
                    >
                      <MenuItem value={"60"}>60 min</MenuItem>
                      <MenuItem value={"120"}>120 min</MenuItem>
                      <MenuItem value={"180"}>180 min</MenuItem>
                      <MenuItem value={"240"}>240 min</MenuItem>
                      );
                    </Select>
                  </TableCell>
                  <TableCell
                    sx={{ width: "120px" }}
                    component="th"
                    scope="row"
                    className={"userRow"}
                    onClick={() => {
                      setSelectedProfile(profile);
                      navigate("resources");
                    }}
                  >
                    <Typography fontSize={14}>Go to details</Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : profilesList === undefined ? (
              <Box display={"flex"} p={5}>
                <CircularProgress />
              </Box>
            ) : (
              <Typography fontSize={22} fontWeight={600}>
                There are no profiles.
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default Profiles;
