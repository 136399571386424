import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import axios from "axios";
import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";

const Incidents = () => {
  const navigate = useNavigate();
  const activeComputer = useUserStore((state) => state.activeComputer);

  useEffect(() => {
    console.log("here is incidents before deviceList.length");
    const goToIncidents = async () => {
      if (activeComputer) {
        const {
          data: { token },
        } = await axios.get(`${NGROK}/api/influxdb/get-token`);
        console.log(`tokrn = ${token}`)
        window.open(`${activeComputer.logurl}?token=${token}`, "_blank");
        navigate("/endpoints");
      }
    };
    goToIncidents();
  }, [activeComputer]);

  console.log(activeComputer, "activeComputer");
  return (
    <div style={{ fontSize: "24px" }}>
      THERE IS NO ACTIVE COMPUTER TO ACHIVE INCIDENTS
    </div>
  );
};

export default Incidents;
