import React, { useCallback } from "react";

import axios from "axios";
import { useState, useEffect } from "react";
import { NGROK } from "../../APIs";
import useUserStore from "../../services/userStore";
import { useNavigate, Link } from "react-router-dom";

import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Autocomplete,
  TextField,
  Stack,
  InputLabel,
  Checkbox,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { myLocalStorage } from "../../components/StorageHelper";
import ConfirmAlert from "../components/ConfirmAlert";

const Endpoints = () => {
  const navigate = useNavigate();

  const [tenantsList, setTenantsList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [storageTenants, setStorageTenants] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState(undefined);
  const [openDeleteDeviceAlert, setOpenDeleteDeviceAlert] = useState(false);

  const userData = useUserStore((state) => state.user);
  const setActiveComputer = useUserStore((state) => state.setActiveComputer);
  const activeComputer = useUserStore((state) => state.activeComputer);
  const setComputerId = useUserStore((state) => state.setComputerId);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);

  const setLastSession = useUserStore((state) => state.setLastSession);
  const lastSession = useUserStore((state) => state.lastSession);

  const { latestTenant } = lastSession;

  const fetchDataProfiles = async (computerId) => {
    const response = await axios.get(
      `${NGROK}/api/${selectedTenant.tenantName}/computers/${computerId}/profiles`
    );
    setProfiles(response.data);
    return response.data;
  };

  const getComputers = useCallback(
    async (tenantName) => {
      const storageKey = `${tenantName}/computers-30min`;
      const storageComputers = myLocalStorage.getItem(storageKey);

      try {
        const response = await axios.get(
          `${NGROK}/api/${tenantName}/computers`
        );
        if (response.data.length) {
          setActiveComputer(response.data[0]);

          const dataSortedById = response.data.sort((a, b) =>
            a.id > b.id ? 1 : -1
          );

          if (userData.role === "TENANT_USER") {
            const filteredDevices = response.data.filter((device) =>
              userData.email
                .toLowerCase()
                .includes(device.dNSHostName.toLowerCase())
            );
            console.log(filteredDevices, "filteredDevices");
            setDeviceList(filteredDevices);
          } else setDeviceList(dataSortedById);

          myLocalStorage.setItem(storageKey, response.data, 30);
        }

        const updateDevicesEmptyFields = async () => {
          const updatedDevices = [];

          const handleDeviceProfile = async (device) => {
            const profiles = await fetchDataProfiles(device.id);
            const profile = profiles[0];
            if (profile) {
              const responseFirst = await axios.put(
                `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}/update-computer`
              );
              axios.put(
                `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}`
              );

              return responseFirst.data;
            } else return device;
          };

          const handleDeviceMode = async (device) => {
            const response = await axios.put(
              `${NGROK}/api/computers/${device.id}/operation-mode`,
              { mode: "AUDITING" }
            );
            return response.data;
          };
          const devices =
            userData.role === "TENANT_USER"
              ? response.data.filter((device) =>
                  userData.email
                    .toLowerCase()
                    .includes(device.dNSHostName.toLowerCase())
                )
              : response.data;
          for (const device of devices) {
            if (device.mode && device.profile) {
              updatedDevices.push(device);
            } else {
              if (!device.profile && device.mode) {
                const deviceWithProfile = await handleDeviceProfile(device);
                console.log(deviceWithProfile, "deviceWithProfile");
                updatedDevices.push(deviceWithProfile);
              } else if (device.profile && !device.mode) {
                const deviceWithMode = await handleDeviceMode(device);
                console.log(deviceWithMode, "deviceWithMode");
                updatedDevices.push(deviceWithMode);
              } else if (!device.profile && !device.mode) {
                const deviceWithProfile = await handleDeviceProfile(device);
                const deviceWithMode = await handleDeviceMode(device);
                console.log(deviceWithMode, "deviceWithMode");
                updatedDevices.push({
                  ...deviceWithProfile,
                  mode: deviceWithMode.mode,
                });
              }
            }
          }
          return updatedDevices;
        };
        const updatedDevices = await updateDevicesEmptyFields();
        console.log(updatedDevices, "updatedDevices");
        setDeviceList(updatedDevices);
      } catch (error) {
        console.log("computers error", error);
      }
    },
    [selectedTenant]
  );

  const updateDeviceProfile = async (event, device, defaultProfile) => {
    const profileName = defaultProfile ? defaultProfile : event.target.value;
    const profile = profiles?.find((el) => el.name === profileName);

    if (profile) {
      const updatedDevice = { ...device, profile: profileName };
      const updatedDevices = deviceList.map((element, index) =>
        element.id === updatedDevice.id
          ? (deviceList[index] = updatedDevice)
          : element
      );
      setDeviceList(updatedDevices);

      await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}/update-computer`
      );
      await axios.put(
        `${NGROK}/api/${selectedTenant.tenantName}/computers/${device.id}/profiles/${profile.id}`
      );
    }
  };

  const fetchTenantsData = async () => {
    const response = await axios.get(
      `${NGROK}/api/get-all-domains?email=${userData.email}`
    );
    setTenantsList(response.data);

    const storageKey = `api/get-all-domains?email=${userData.email}-30min`;
    myLocalStorage.setItem(storageKey, response.data, 30);
    setStorageTenants(myLocalStorage.getItem(storageKey));
  };

  const updateDeviceOperationMode = async (event, device, defaultMode) => {
    const newMode = defaultMode ? defaultMode : event.target.value;
    const updatedDevice = { ...device, mode: newMode };

    const updatedDevices = deviceList.map((element, index) =>
      element.id === updatedDevice.id
        ? (deviceList[index] = updatedDevice)
        : element
    );
    setDeviceList(updatedDevices);

    await axios.put(`${NGROK}/api/computers/${device.id}/operation-mode`, {
      mode: newMode,
    });
  };

  const updateTenantOperationMode = async (event, tenant) => {
    const newMode = event.target.value;
    const updatedTenant = { ...tenant, mode: newMode };

    setSelectedTenant(updatedTenant);

    await axios.put(`${NGROK}/api/${tenant.tenantName}/operation-mode`, {
      mode: newMode,
    });
  };

  const getGuacConnectionDetails = async (computer) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/connection/get-details?computerId=${computer.id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const createGuacConnection = async (computer) => {
    try {
      const response = await axios.get(
        `${NGROK}/api/guacamole/create-connection?computerId==${computer.id}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const connectComputer = async (computer) => {
    let connectionData = await getGuacConnectionDetails(computer);
    if (!connectionData) connectionData = await createGuacConnection(computer);

    const inputData = `${connectionData.identifier}\0c\0postgresql`;
    const mqString = btoa(inputData);

    const {
      data: { authToken },
    } = await axios.get(
      `${NGROK}/api/guacamole/get-token/system-url`
    );

    window.open(
      `${computer.guachost}/#/client/${mqString}?token=${authToken}`,
      "_blank"
    );
  };

  const toggleRecording = async (computer) => {
    let connectionData = await getGuacConnectionDetails(computer);

    if (!connectionData) connectionData = await createGuacConnection(computer);

    const toggleRecordingLocally = () => {
      const updatedComputer = {
        ...computer,
        recordingPathEnabled: !computer.recordingPathEnabled,
      };
      console.log(updatedComputer, "updatedComputer")
      setDeviceList(
        deviceList.map((el) =>
          el.id === updatedComputer.id ? updatedComputer : el
        )
      );
    };
    toggleRecordingLocally();

    await axios.put(`${NGROK}/api/guacamole/update-connection?`, {
      url: computer.guachost,
      id: connectionData.id,
      enableRecordingPath: !computer.recordingPathEnabled,
    });
  };

  useEffect(() => {
    if (tenantsList.length === 1) return setSelectedTenant(tenantsList[0]);

    if (latestTenant && tenantsList.length) {
      const tenant = tenantsList.find(
        (tenant) => tenant.tenantName === latestTenant
      );

      if (tenant !== undefined) setSelectedTenant(tenant);
      else setSelectedTenant(tenantsList[0]);
    } else if (!latestTenant && tenantsList.length)
      setSelectedTenant(tenantsList[0]);
  }, [tenantsList]);

  useEffect(() => {
    if (selectedTenant) {
      const interval = setInterval(() => {
        getComputers(selectedTenant.tenantName);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [selectedTenant]);

  useEffect(() => {
    getComputers(selectedTenant?.tenantName);
  }, [selectedTenant]);

  useEffect(() => {
    if (storageTenants && storageTenants.length && isCachingEnabled) {
      setTenantsList([]);
      setTimeout(() => {
        setTenantsList(storageTenants);
      }, 500);
    } else {
      fetchTenantsData();
    }
  }, []);

  console.log(tenantsList, "tenantsst");
  console.log(deviceList, "deviceList");
  console.log(profiles, "profiles");
  console.log(selectedTenant, "selectedTenant");
  console.log(lastSession, "lastSession");
  console.log(window.location.href, "window.location.href");

  return (
    <>
      <Stack spacing={5}>
        <ConfirmAlert
          open={openDeleteDeviceAlert}
          setOpen={setOpenDeleteDeviceAlert}
          headerText={`Are you sure, you want to delete this computer - ${
            activeComputer?.cn
              ? activeComputer?.cn
              : activeComputer?.dNSHostName
              ? activeComputer?.dNSHostName
              : "unknown computer"
          }?`}
        />
        {tenantsList.length > 1 ? (
          <Stack direction={"row"} spacing={4}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              value={
                selectedTenant !== undefined ? selectedTenant.tenantName : " "
              }
              options={tenantsList.map((tenant) => tenant.tenantName)}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Tenant List" />
              )}
              onChange={(e, value) => {
                const tenant = tenantsList.find(
                  (tenant) => tenant.tenantName === value
                );
                if (tenant !== undefined) {
                  setSelectedTenant(tenant);
                  setLastSession({
                    email: userData.email,
                    latestTenant: tenant.tenantName,
                    latestTenantMode: tenant.mode,
                  });
                }
              }}
            />
            {selectedTenant ? (
              <FormControl sx={{ display: "flex", alignItems: "center" }}>
                <InputLabel id="demo-simple-select-label">Mode</InputLabel>
                <Select
                  sx={{ minWidth: "100px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Operation Mode"
                  value={selectedTenant.mode}
                  size="medium"
                  onChange={(event) =>
                    updateTenantOperationMode(event, selectedTenant)
                  }
                >
                  <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                  <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                  <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                  );
                </Select>
              </FormControl>
            ) : null}
          </Stack>
        ) : null}
        {deviceList && deviceList.length ? (
          <TableContainer
            sx={{ minWidth: "1200px", maxWidth: "80%", height: "fit-content" }}
            component={Paper}
          >
            <Table aria-label="simple table">
              <TableHead
                sx={{
                  backgroundColor: "#233044",
                }}
              >
                <TableRow>
                  <TableCell sx={{ color: "white", fontSize: "16px" }}>
                    Device
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Profile
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Operating Mode
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Resources
                  </TableCell>
                  <TableCell
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Sessions
                  </TableCell>
                  <TableCell
                    colSpan={1}
                    sx={{ color: "white", fontSize: "16px" }}
                    align="center"
                  >
                    Access
                  </TableCell>
                  {userData.role !== "TENANT_USER" ? (
                    <>
                      <TableCell
                        colSpan={1}
                        sx={{ color: "white", fontSize: "16px" }}
                        align="center"
                      >
                        Recording
                      </TableCell>

                      <TableCell
                        colSpan={1}
                        sx={{ color: "white", fontSize: "16px" }}
                        align="center"
                      >
                        Management
                      </TableCell>
                    </>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {deviceList.map((device) => (
                  <TableRow
                    key={device.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                    onClick={() => {
                      setActiveComputer(device);
                    }}
                  >
                    <TableCell sx={{ fontSize: "16px" }}>
                      {device.cn ? device.cn : device.dNSHostName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <FormControl>
                        <Select
                          disabled={userData.role === "TENANT_USER"}
                          sx={{ minWidth: "150px" }}
                          onOpen={() => fetchDataProfiles(device.id)}
                          onClose={() => setProfiles([])}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={device.profile && device.profile}
                          size="small"
                          onChange={(event) =>
                            updateDeviceProfile(event, device)
                          }
                        >
                          {profiles &&
                            profiles.map((profile) => {
                              if (profile.name !== device.profile)
                                return (
                                  <MenuItem
                                    value={profile.name}
                                    key={profile.id}
                                  >
                                    {profile.name
                                      ? profile.name
                                      : "Select profile"}
                                  </MenuItem>
                                );
                            })}
                          <MenuItem value={device.profile}>
                            {device.profile}
                          </MenuItem>
                          {!profiles && (
                            <MenuItem value="">Loading...</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }} align="center">
                      <Select
                        disabled={userData.role === "TENANT_USER"}
                        sx={{ minWidth: "100px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={device.mode ? device.mode : " "}
                        size="small"
                        onChange={(event) =>
                          updateDeviceOperationMode(event, device)
                        }
                      >
                        <MenuItem value={"AUDITING"}>AUDITING</MenuItem>
                        <MenuItem value={"ENFORCING"}>ENFORCING</MenuItem>
                        <MenuItem value={"DISABLED"}>DISABLED</MenuItem>
                        <MenuItem value={"INHERITED_FROM_PROFILE"}>
                          INHERITED{" "}
                          {`(${
                            device.profileMode !== "INHERITED_FROM_TENANT"
                              ? device.profileMode
                              : selectedTenant.mode
                          })`}
                        </MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        navigate("resources");
                      }}
                      sx={{ fontSize: "16px" }}
                      align="center"
                    >
                      <Button
                        variant="text"
                        onClick={() => {
                          setComputerId(device.id);
                          setLastSession({
                            ...lastSession,
                            email: userData.email,
                            latestTenant: selectedTenant.tenantName,
                            latestTenantMode: selectedTenant.mode,
                            latestComputerId: device.id,
                          });
                        }}
                      >
                        Resources
                      </Button>
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        if (userData.role !== "TENANT_USER")
                          navigate("sessions");
                      }}
                      sx={{ fontSize: "16px" }}
                      align="center"
                    >
                      <Button
                        disabled={userData.role === "TENANT_USER"}
                        variant="text"
                        onClick={() => {
                          setComputerId(device.id);
                        }}
                      >
                        Sessions
                      </Button>
                    </TableCell>
                    <TableCell
                      onClick={() => console.log("click on link")}
                      sx={{ fontSize: "16px" }}
                      align="center"
                    >
                      <Button onClick={() => connectComputer(device)}>
                        Connect
                      </Button>
                    </TableCell>

                    {userData.role !== "TENANT_USER" ? (
                      <>
                        <TableCell
                          onClick={() => console.log("click on link")}
                          sx={{ fontSize: "16px" }}
                          align="center"
                        >
                          <Checkbox
                            checked={device.recordingPathEnabled}
                            onChange={() => toggleRecording(device)}
                          />
                        </TableCell>
                        <TableCell sx={{ fontSize: "16px" }} align="center">
                          <Button
                            onClick={() =>
                              setOpenDeleteDeviceAlert(!openDeleteDeviceAlert)
                            }
                            size="small"
                            variant="outlined"
                            color="error"
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : deviceList === undefined ? (
          <Box display={"flex"} p={5}>
            <CircularProgress />
          </Box>
        ) : (
          <Box pl={5}>
            <p style={{ fontSize: "20px", fontWeight: "500" }}>
              There are no any computers.
            </p>
          </Box>
        )}
      </Stack>
    </>
  );
};

export default Endpoints;
