import "react-app-polyfill/stable";

import React, { useEffect } from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import UserService from "./services/UserServices";
import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import keycloak from "./keycloak";

import "./mocks";
import "./index.scss";
import { NGROK, preLink } from "./APIs";

// import { ThemeProvider } from "./contexts/ThemeContext";

const renderApp = () =>
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );

const generalInit = async () => {
  const response = await axios.get(`${NGROK}/api/keycloak-url`);
  const url = response.data;

  UserService.setUpKc(url);
  UserService.initKeycloak(renderApp);

  reportWebVitals();
};

generalInit()
