import React, { useEffect, useState } from "react";

import axios from "axios";

import {
  Autocomplete,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import "./pages.scss";
import Paper from "@mui/material/Paper";
import ApplicationsControl from "./ApplicationsControl";
import DataAccess from "./DataAccess";
import PrivilegeManagement from "./PrivilegeManagement";
import useUserStore from "../../services/userStore";
import { myLocalStorage } from "../../components/StorageHelper";
import { NGROK } from "../../APIs";
import { useLocation } from "react-router-dom";

const EndpointsResources = () => {
  const location = useLocation();
  const activeDevice = location.state?.activeDevice;

  const [selectedResourceId, setSelectedResourcesId] = useState(null);

  const [publishers, setPublishers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [folders, setFolders] = useState([]);
  const [applications, setApplications] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(undefined);
  const [userSearchValues, setUserSearchValues] = useState("");

  const userData = useUserStore((state) => state.user);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);
  const setLastSession = useUserStore((state) => state.setLastSession);
  const lastSession = useUserStore((state) => state.lastSession);
  const [showCount, setShowCount] = useState(50);

  const { latestTenant, latestComputerId, latestUserId, resourceId } =
    lastSession;

  const getUsers = () => {
    setUserSearchValues("");
    if (userData.role === "TENANT_ADMIN" || userData.role === "SYS_ADMIN") {
      axios
        .get(`${NGROK}/api/${latestTenant}/computer-users/${latestComputerId}`)
        .then((res) => {
          if (res.data) {
            setUsersList(res.data);
          }
        });
    } else if (userData.role === "TENANT_USER") {
      axios
        .get(
          `${NGROK}/api/${latestTenant}/computer-users/${latestComputerId}/users?email=${userData.email}`
        )
        .then((res) => {
          if (res.data) {
            setUsersList(res.data);
          }
        });
    }
  };

  const getPublishers = async () => {
    if (selectedUser !== undefined) {
      setPublishers(undefined);

      const storageKey = `${latestTenant}/computers/${latestComputerId}/publishers-30min`;
      const storagePublishers = myLocalStorage.getItem(storageKey);

      if (storagePublishers && storagePublishers.length && isCachingEnabled) {
        setTimeout(() => {
          setPublishers(storagePublishers);
        }, 500);
      } else {
        const response = await axios.get(
          `${NGROK}/api/${latestTenant}/computers/${latestComputerId}/publishers`
        );
        setPublishers(response.data);

        myLocalStorage.setItem(storageKey, response.data, 30);
      }
    }
  };

  const getFolders = async () => {
    setFolders(undefined);

    if (selectedUser !== undefined) {
      const storageKey = `${latestTenant}/computers/${latestComputerId}/computer-users/${selectedUser.id}/folders-30min`;
      const storageFolders = myLocalStorage.getItem(storageKey);

      if (storageFolders && storageFolders.length && isCachingEnabled) {
        setTimeout(() => {
          setFolders(storageFolders);
        }, 500);
      } else {
        const response = await axios.get(
          `${NGROK}/api/${latestTenant}/computers/${latestComputerId}/computer-users/${selectedUser.id}/folders`
        );
        if (response.data) {
          setFolders(response.data);
          myLocalStorage.setItem(storageKey, response.data, 30);
        }
      }
    }
  };

  const getUserGroups = async () => {
    setGroups(undefined);

    if (selectedUser !== undefined) {
      const storageKey = `${latestTenant}/computer-user-groups/membership/${selectedUser.id}-30min`;
      const storageUserGroups = myLocalStorage.getItem(storageKey);

      if (storageUserGroups && storageUserGroups.length && isCachingEnabled) {
        setTimeout(() => {
          setGroups(storageUserGroups);
        }, 500);
      } else {
        axios
          .get(
            `${NGROK}/api/${latestTenant}/computer-user-groups/membership/${selectedUser?.id}`
          )
          .then((res) => {
            if (res.data) {
              setGroups(res.data.content);
              myLocalStorage.setItem(storageKey, res.data.content, 30);
            }
          });
      }
    }
  };

  useEffect(() => {
    setShowCount(50);
    switch (selectedResourceId) {
      case 1:
        getPublishers();
        break;
      case 2:
        getFolders();
        break;
      case 3:
        getUserGroups();
        break;
      default:
        console.log("default");
    }
  }, [selectedResourceId, selectedUser]);

  useEffect(() => {
    getUsers();
    if (!resourceId) setSelectedResourcesId(1);
    else setSelectedResourcesId(resourceId);
  }, []);

  useEffect(() => {
    if (usersList.length === 1) return setSelectedUser(usersList[0]);
    console.log(latestUserId, "latestUserId");
    if (latestUserId && usersList.length) {
      const user = usersList.find((user) => user.id === latestUserId);

      if (user !== undefined) setSelectedUser(user);
      else setSelectedUser(usersList[0]);
    } else if (!latestUserId && usersList.length) setSelectedUser(usersList[0]);
  }, [usersList]);

  useEffect(() => {
    if (selectedUser) {
      setApplications([]);
    }
  }, [selectedUser]);

  console.log(lastSession, "lastSession");
  console.log(selectedResourceId, "selectedResourceId");
  console.log(selectedUser, "selectedUser");
  console.log(publishers, "publishers resources")
  return (
    <Stack spacing={3}>
      {selectedUser && selectedUser.id ? (
        <section className="domainTab">
          <Resources
            selectedResourceId={selectedResourceId}
            setSelectedResourcesId={setSelectedResourcesId}
            lastSession={lastSession}
            setLastSession={setLastSession}
          />
          <Stack direction={"row"} spacing={3}>
            {usersList && usersList.length ? (
              <GeneralTab
                activeDevice={activeDevice}
                users={usersList}
                selectedUserId={selectedUser.id}
                setSelectedUser={setSelectedUser}
                lastSession={lastSession}
                setLastSession={setLastSession}
                setUserSearchValues={setUserSearchValues}
                userSearchValues={userSearchValues}
              />
            ) : null}
            {selectedResourceId === 1 ? (
              <ApplicationsControl
                userData={userData}
                setApplications={setApplications}
                applications={applications}
                selectedUserId={selectedUser.id}
                publishers={publishers}
                selectedTenantName={latestTenant}
                setGroups={setGroups}
                showCount={showCount}
                setShowCount={setShowCount}
              />
            ) : null}
            {selectedResourceId === 2 ? (
              <DataAccess
                userData={userData}
                setFolders={setFolders}
                activeDevice={activeDevice}
                getFolders={getFolders}
                folders={folders}
                selectedTenantName={latestTenant}
                showCount={showCount}
                setShowCount={setShowCount}
              />
            ) : null}
            {selectedResourceId === 3 ? (
              <PrivilegeManagement
                userData={userData}
                groups={groups}
                setGroups={setGroups}
                getUserGroups={getUserGroups}
                activeDevice={activeDevice}
                selectedTenantName={latestTenant}
                showCount={showCount}
                setShowCount={setShowCount}
              />
            ) : null}
          </Stack>
        </section>
      ) : null}
    </Stack>
  );
};

const Resources = ({
  selectedResourceId,
  setSelectedResourcesId,
  lastSession,
  setLastSession,
}) => {
  const resources = [
    {
      id: 1,
      title: "Application Control",
    },
    {
      id: 2,
      title: "Data Access",
    },
    {
      id: 3,
      title: "Privilege Management",
    },
  ];

  return (
    <section>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table sx={{ width: "100%" }} size="large">
          <TableHead>
            <TableRow>
              <TableCell className="userName">Resources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResourceId
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  setSelectedResourcesId(resource.id);
                  setLastSession({
                    ...lastSession,
                    resourceId: resource.id,
                  });
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="userRowName userName"
                  title={resource.title}
                >
                  {resource.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};

const GeneralTab = ({
  activeDevice,
  users,
  selectedUserId,
  setSelectedUser,
  setLastSession,
  lastSession,
  userSearchValues,
  setUserSearchValues,
}) => {
  const findUser = (e) => {
    setTimeout(() => {
      setUserSearchValues(e.target.value);
    }, 1500);
  };

  const filteredUsers = users.filter((user) => {
    if (userSearchValues === "") {
      return user;
    } else if (user.cn.toLowerCase().includes(userSearchValues.toLowerCase())) {
      return user;
    }
  });

  console.log(users, "users");
  return (
    <section>
      {users !== undefined && users.length ? (
        <TableContainer
          style={{ height: "fit-content", maxWidth: "400px" }}
          component={Paper}
        >
          <Table sx={{ width: "100%" }} size="large">
            <TableHead>
              <TableRow>
                <TableCell className="userName">Users</TableCell>
              </TableRow>
              {users.length > 10 ? (
                <TableRow>
                  <TableCell>
                    <TextField
                      fullWidth
                      sx={{ minWidth: "200px" }}
                      label="Find user ..."
                      id="searchForUser"
                      onChange={findUser}
                    />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow
                  className={
                    user.id === selectedUserId
                      ? "userRow activeUserRow"
                      : "userRow"
                  }
                  key={user.cn}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className="userRowName userName"
                    title={user.cn}
                    onClick={() => {
                      setSelectedUser(user);
                      setLastSession({
                        ...lastSession,
                        latestUserId: user.id,
                        latestUsername: user.cn,
                      });
                    }}
                  >
                    <Typography variant="h5" className="userRowName userName">
                      {user.cn}
                    </Typography>
                    {/* <Typography
                          variant="h5"
                          className="userRowName userName"
                        >
                          {user.loginStatus !== null ? (
                            <>
                              {user.loginStatus === "NOT_LOGGED"
                                ? "Not Logged In"
                                : `${user.loginStatus}`}
                            </>
                          ) : (
                            "Not Logged In"
                          )}
                        </Typography> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : users == undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <p style={{ fontSize: "20px", fontWeight: "500" }}>
          This computer doesn't have any users.
        </p>
      )}
    </section>
  );
};

export default EndpointsResources;
