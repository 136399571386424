import {
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Layout,
  List,
  Sliders,
  Users,
  User,
  Settings,
  Italic
} from "react-feather";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import PaymentIcon from "@mui/icons-material/Payment";
import DownloadIcon from "@mui/icons-material/Download";

// localStorage.setItem("userAccess", "TENANT_ADMIN");

const docsSection = [
  {
    href: "/getstart",
    icon: Layout,
    title: "Getting Started",
  },
];

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
      // {
      //   href: "/dashboard/analytics",
      //   title: "Analytics",
      // },
    ],
  },
  {
    href: "/incidents",
    icon: Italic,
    title: "Incidents",
  },
  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/policyProfiles",
    icon: Layout,
    title: "Policy Profiles",
  },
  {
    href: "/tenant-management",
    icon: SupervisorAccountIcon,
    title: "Tenant Management",
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  // {
  //   href: "/calendar",
  //   icon: Calendar,
  //   title: "Calendar",
  // },
  // {
  //   href: "/pricing",
  //   icon: PaymentIcon,
  //   title: "Pricing",
  // },
  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
];

const usersPagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Dashboard",
    children: [
      {
        href: "/dashboard/default",
        title: "Default",
      },
      // {
      //   href: "/dashboard/analytics",
      //   title: "Analytics",
      // },
    ],
  },
  {
    href: "/endpoints",
    icon: CreditCard,
    title: "Endpoints",
  },
  {
    href: "/account",
    icon: Settings,
    title: "Account",
  },
  {
    href: "/downloads",
    icon: DownloadIcon,
    title: "Downloads",
  },
];

const sysAdminPageSection = [
  {
    href: "/tenant-management",
    icon: <SupervisorAccountIcon fontSize="large" />,
    title: "Tenant Management",
  },
];

export const adminNavsItems = [
  {
    title: "",
    pages: [
      ...pagesSection,
    ],
  },
  {
    title: "",
    pages: docsSection,
  },
];
export const sysAdminNavsItems = [
  {
    title: "",
    pages: [...pagesSection],
  },
  {
    title: "",
    pages: docsSection,
  },
];

export const userNavsItems = [
  {
    title: "",
    pages: usersPagesSection,
  },
  {
    title: "",
    pages: docsSection,
  },
];
