import React, { useState, useEffect } from "react";
import axios from "axios";
import { NGROK } from "../../APIs";

import "./pages.scss";
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ProfileApplicationsControl from "./ProfileApplicationsControl";
import ProfileDataAcces from "./ProfileDataAcces";
import ProfilePrivilegeManagement from "./ProfilePrivilegeManagement";
import useUserStore from "../../services/userStore";
import { myLocalStorage } from "../../components/StorageHelper";

const ProfileResources = () => {
  const [selectedPublisher, setSelectedPublisher] = useState(undefined);

  const [publishers, setPublishers] = useState([]);
  const [applications, setApplications] = useState([]);
  const [folders, setFolders] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedResourceId, setSelectedResourcesId] = useState(0);
  const [showCount, setShowCount] = useState(50);

  const userData = useUserStore((state) => state.user);
  const selectedProfile = useUserStore((state) => state.selectedProfile);
  const isCachingEnabled = useUserStore((state) => state.isCachingEnabled);
  const lastSession = useUserStore((state) => state.lastSession);
  const setLastSession = useUserStore((state) => state.setLastSession);

  const getFolders = async (profile) => {
    setFolders(undefined);

    const storageKey = `${profile.os}/profiles/${profile.id}/folders-30min`;
    const storageFolders = myLocalStorage.getItem(storageKey);

    if (storageFolders && storageFolders.length && isCachingEnabled) {
      setTimeout(() => {
        setFolders(storageFolders);
      }, 500);
    } else {
      const response = await axios.get(
        `${NGROK}/api/${profile.os}/profiles/${profile.id}/folders`
      );
      if (response.data) {
        setFolders(response.data.content);
        myLocalStorage.setItem(storageKey, response.data.content, 30);
      }
    }
  };

  const getProfileGroups = async (profile) => {
    setGroups(undefined);

    const storageKey = `${profile.os}/profiles/${profile.id}/membership-30min`;
    const storageUserGroups = myLocalStorage.getItem(storageKey);

    if (storageUserGroups && storageUserGroups.length && isCachingEnabled) {
      setTimeout(() => {
        setGroups(storageUserGroups);
      }, 500);
    } else {
      const response = await axios.get(
        `${NGROK}/api/${profile.os}/profiles/${profile.id}/membership`
      );

      if (response.data) {
        setGroups(response.data.content);
        myLocalStorage.setItem(storageKey, response.data.content, 30);
      }
    }
  };

  const getPublishers = async () => {
    setPublishers(undefined);

    const storageKey = `${selectedProfile.os}/profiles/${selectedProfile.id}/publishers-30min`;
    const storagePublishers = myLocalStorage.getItem(storageKey);

    if (storagePublishers && storagePublishers.length && isCachingEnabled) {
      setTimeout(() => {
        setPublishers(storagePublishers);
      }, 500);
    } else {
      const response = await axios.get(
        `${NGROK}/api/${selectedProfile.os}/profiles/${selectedProfile.id}/publishers`
      );
      if (response.data) {
        setPublishers(response.data.content);
        myLocalStorage.setItem(storageKey, response.data.content, 30);
      }
    }
  };

  useEffect(() => {
    setShowCount(50);
    switch (selectedResourceId) {
      case 1:
        getPublishers();
        break;
      case 2:
        getFolders(selectedProfile);
        break;
      case 3:
        getProfileGroups(selectedProfile);
        break;
      default:
        console.log("default");
    }
  }, [selectedResourceId]);

  useEffect(() => {
    if (!lastSession.resourceId) setSelectedResourcesId(1);
    else setSelectedResourcesId(lastSession.resourceId);
  }, []);

  console.log(lastSession, "lastSession");
  console.log(selectedProfile, "selectedProfile");
  return (
    <Stack spacing={4}>
      <section className="domainTab">
        <Resources
          selectedResourceId={selectedResourceId}
          setSelectedResourcesId={setSelectedResourcesId}
          lastSession={lastSession}
          setLastSession={setLastSession}
        />

        {selectedResourceId === 1 && (
          <ProfileApplicationsControl
            userData={userData}
            selectedPublisher={selectedPublisher}
            applications={applications}
            setApplications={setApplications}
            setSelectedPublisher={setSelectedPublisher}
            selectedProfile={selectedProfile}
            publishers={publishers}
            showCount={showCount}
            setShowCount={setShowCount}
          />
        )}
        {selectedResourceId === 2 && (
          <ProfileDataAcces
            userData={userData}
            setFolders={setFolders}
            folders={folders}
            getFolders={getFolders}
            selectedProfileId={selectedProfile.id}
            showCount={showCount}
            setShowCount={setShowCount}
          />
        )}
        {selectedResourceId === 3 && (
          <ProfilePrivilegeManagement
            selectedProfileId={selectedProfile.id}
            userData={userData}
            setGroups={setGroups}
            groups={groups}
            getProfileGroups={getProfileGroups}
            showCount={showCount}
            setShowCount={setShowCount}
          />
        )}
      </section>
    </Stack>
  );
};

const Resources = ({
  selectedResourceId,
  setSelectedResourcesId,
  lastSession,
  setLastSession,
}) => {
  const resources = [
    {
      id: 1,
      title: "Application Control",
    },
    {
      id: 2,
      title: "Data Access",
    },
    {
      id: 3,
      title: "Privilege Management",
    },
  ];

  return (
    <section>
      <TableContainer style={{ height: "fit-content" }} component={Paper}>
        <Table sx={{ width: "100%" }} size="large">
          <TableHead>
            <TableRow>
              <TableCell className="userName">Resources</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resources.map((resource) => (
              <TableRow
                className={
                  resource.id === selectedResourceId
                    ? "userRow activeUserRow"
                    : "userRow"
                }
                key={resource.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => {
                  setSelectedResourcesId(resource.id);
                  setLastSession({
                    ...lastSession,
                    resourceId: resource.id,
                  });
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  className="userRowName userName"
                  title={resource.title}
                >
                  {resource.title}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};
export default ProfileResources;
