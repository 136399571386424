import { styled } from "@mui/material/styles";
import { tooltipClasses, Tooltip } from "@mui/material";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: "1px solid #E6E8ED",
    backgroundColor: "#233044",
    maxWidth: 400,
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: "1px solid #233044",
    },
    color: "#233044",
    fontSize: 20,
  },
}));

export default StyledTooltip