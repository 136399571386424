import create from "zustand";
import axios from "axios";
import { NGROK } from "../APIs";
import UserService from "./UserServices";

const useUserStore = create((set) => ({
  user: {},
  loading: false,
  hasErrors: false,

  tenantName: "",
  apiKey: "",
  userName: "",
  isCachingEnabled: "",
  activeComputer: null,
  computerId: null,
  defaultTenant: undefined,
  selectedProfile: undefined,

  resourcesDefaultTime: {
    windows_normal: "180",
    windows_secure: "120",
    windows_critical: "60",
    windows_original: "240",
    linux_normal: "180",
    linux_secure: "120",
    linux_critical: "60",
    linux_original: "240",
  },

  lastSession: {
    email: null,
    latestTenant: null,
    latestComputerId: null,
    latestUserId: null,
    resourceId: null,
    latestPublisherId: null,
    latestAppId: null,
    latestFolderId: null,
    latestGroupId: null,
    latestTenantMode: null,
    latestUsername: null,
  },

  setApiKey: (key) => {
    set(() => ({ apiKey: key }));
  },
  setTenantName: (name) => {
    set(() => ({ tenantName: name }));
  },
  setDefaultTenant: (tenant) => {
    set(() => ({ defaultTenant: { ...tenant } }));
  },
  setActiveComputer: (computer) => {
    set(() => ({ activeComputer: { ...computer } }));
  },
  setLastSession: (session) => {
    set(() => ({ lastSession: { ...session } }));
  },
  setSelectedProfile: (profile) => {
    set(() => ({ selectedProfile: { ...profile } }));
  },
  setUserName: (name) => {
    set(() => ({ userName: name }));
  },
  setComputerId: (id) => {
    set(() => ({ computerId: id }));
  },
  setisCachingEnabled: (bullean) => {
    set(() => ({ isCachingEnabled: bullean }));
  },
  setResourcesDefaultTime: (profile, time) => {
    set((state) => ({
      resourcesDefaultTime: {
        ...state.resourcesDefaultTime,
        [profile]: time,
      },
    }));
  },

  logOut: async () => {
    try {
      if (UserService._kc()) {
        UserService.doLogout();
        let data_email = UserService._kc().tokenParsed.email;
        axios
          .get(`${NGROK}/api/logout?email=${data_email}`)
          .then((res) => {
            console.log("user Data from DB", res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (error) {
      set(() => ({ hasErrors: true }));
    }
  },

  sessionMinutesLeft: 0,
  setSessionMinutesLeft: (minutes) =>
    set(() => ({ sessionMinutesLeft: minutes })),

  fetch: async () => {
    set(() => ({ loading: true }));
    try {
      const response = await axios.post(`${NGROK}/api/login`, {
        firstName: UserService._kc().tokenParsed.family_name,
        lastName: UserService._kc().tokenParsed.given_name,
        sub: UserService._kc().tokenParsed.sub,
        emailOrUsername: UserService._kc().tokenParsed.email,
      });
      set((state) => ({ user: (state.data = response.data), loading: false }));
    } catch (err) {
      set(() => ({ hasErrors: true, loading: false }));
    }
  },
}));

export default useUserStore;
