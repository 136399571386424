import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";

import "./pages.scss";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import SessionTime from "../../components/SessionTime";
import useUserStore from "../../services/userStore";
import StyledTooltip from "../components/StyledTooltip";
import { NGROK } from "../../APIs";

const SessionsAndProcesses = () => {
  const [sessionsList, setSessionsList] = useState([]);
  const [processesList, setProcessesList] = useState([]);
  const [activeSession, setActiveSession] = useState({});

  const computerId = useUserStore((state) => state.computerId);
  const activeComputer = useUserStore((state) => state.activeComputer);

  const getSessions = async () => {
    console.log("get sessions ");
    const response = await axios.get(`${NGROK}/api/computers/${computerId}/sessions`);

    setSessionsList(response.data);
  };

  const getProcesses = async (session) => {
    const response = await axios.get(`${NGROK}/api/sessions/${session.id}/processes`);
    setProcessesList(response.data);
  };

  const connectView = async () => {
    try {

      const {
        data: { authToken },
      } = await axios.get(
        `${NGROK}/api/guacamole/get-token?url=${activeComputer.guachost}`
      );


      window.open(
        `${activeComputer.guachost}/#/settings/postgresql/history?token=${authToken}`,
        "_blank"
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSessions();
  }, []);

  console.log(processesList, "processes");
  console.log(sessionsList, "sessionsList");
  return (
    <Stack direction={"row"} spacing={4} sx={{ width: "100%" }}>
      <TableContainer
        component={Paper}
        sx={{
          overflow: "hidden",
          display: "flex",
          height: "fit-content",
          minWidth: "800px",
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            height: "fit-content",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell sx={{ color: "white", fontSize: "16px" }}>
                Session Name
              </TableCell>
              <TableCell sx={{ color: "white", fontSize: "16px" }}>
                User Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Remote Client
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Start Time
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Duration
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Logs
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sessionsList?.map((session) => (
              <TableRow
                key={`${session.id}${session.sessionId}`}
                onClick={() => {
                  setActiveSession(session);
                  getProcesses(session);
                }}
                className={
                  session.sessionName === activeSession.sessionName
                    ? "tenantRow activeTenantRow"
                    : "tenantRow"
                }
              >
                <TableCell sx={{ fontSize: "16px" }}>
                  {session.sessionName ? session.sessionName : "Default Name"}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  {session.username ? session.username : "System"}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  {session.client ? session.client : "Default Client"}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  {session.startTime}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  <SessionTime session={session} sessionsList={sessionsList} />
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  <Button
                    variant="outlined"
                    onClick={connectView}
                    sx={
                      session.sessionName === activeSession.sessionName
                        ? { color: "white" }
                        : {}
                    }
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer
        // key={`${process.id} ${process.processName} ${process.pid} ${process.ppid} ${process.commandLine}`}
        component={Paper}
        sx={{
          overflow: "hidden",
          height: "fit-content",
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            borderCollapse: "collapse",
            borderStyle: "hidden",
            "& td, & th": {
              border: "1px solid #233044",
            },
          }}
        >
          <TableHead
            sx={{
              backgroundColor: "#233044",
            }}
          >
            <TableRow>
              <TableCell sx={{ color: "white", fontSize: "16px" }}>
                Process Name
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                ID
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                PID
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                User
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Status
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Command Line
              </TableCell>
              <TableCell
                sx={{ color: "white", fontSize: "16px" }}
                align="center"
              >
                Process Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processesList?.map((process) => (
              <TableRow key={`${process.id}${process.pid}`}>
                <TableCell sx={{ fontSize: "16px" }}>
                  {process.processName ? process.processName : "Default Name"}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>{process.id}</TableCell>
                <TableCell sx={{ fontSize: "16px" }}>{process.pid}</TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  {process.username}
                </TableCell>
                <TableCell sx={{ fontSize: "16px" }}>
                  {process.isEnabled ? "Running" : "Stop"}
                </TableCell>
                <StyledTooltip
                  arrow
                  title={
                    <Typography color="inherit">
                      {`${process.commandLine}`}
                    </Typography>
                  }
                  placement="left"
                >
                  <TableCell sx={{ fontSize: "16px" }}>
                    {process.commandLine.substring(0, 20)} ...{" "}
                    {process.commandLine.slice(-20)}
                  </TableCell>
                </StyledTooltip>

                <TableCell sx={{ fontSize: "16px" }}>
                  <SessionTime session={process} sessionsList={processesList} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default SessionsAndProcesses;
