import React from "react";
import axios from "axios";
import { NGROK } from "../../APIs";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import Timer from "../components/Timer";

import "./pages.scss";
import { CircularProgress, MenuItem, Select, Table } from "@mui/material";
import { useState } from "react";
import RadioAuthFilter from "../components/RadioAuthFilter";
import useUserStore from "../../services/userStore";

const DataAccess = ({
  folders,
  setFolders,
  selectedTenantName,
  showCount,
  setShowCount,
}) => {
  const resourcesDefaultTime = useUserStore(
    (state) => state.resourcesDefaultTime
  );
  const activeComputer = useUserStore((state) => state.activeComputer);

  const [filterFolderValue, setFilterFolderValue] = useState("Show All");
  const [snackbarSettings, setSnackbarSettings] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = snackbarSettings;

  const handleSelectTime = (event, uId, fId, type) => {
    if (event.key === "Enter") {
      addFolderToUser(uId, fId, +event.target.value, type);
    }
  };

  const changeFolderStatus = (newFolder) => {
    const updatedFolders = folders.map((folder) =>
      folder.folderId === newFolder.folderId ? newFolder : folder
    );

    setFolders(updatedFolders);
  };

  const addFolderToUser = async (folder, groupStatus) => {
    const profileName = activeComputer.profile;
    const timeInSeconds = resourcesDefaultTime[profileName] * 60;

    changeFolderStatus({ ...folder, groupStatus });
    try {
      const response = await axios.get(
        `${NGROK}/api/${selectedTenantName}/computer-user/folders/timer?userId=${folder.computerUserId}&folderId=${folder.folderId}&groupStatus=${groupStatus}&selectedTime=${timeInSeconds}`
      );
      if (
        groupStatus === "INHERITED" &&
        response.data.inheritedGroupStatus === null
      ) {
        setSnackbarSettings((prev) => ({ ...prev, open: true }));
        setTimeout(() => {
          setSnackbarSettings((prev) => ({ ...prev, open: false }));
        }, 4000);
      }
      changeFolderStatus(response.data);
    } catch (error) {
      changeFolderStatus(folder);
    }
  };

  const handleFilterResourceValue = (e) => {
    setFilterFolderValue(e.target.value);
  };

  const filteredFolders = folders?.filter((folder) => {
    if (filterFolderValue === "Show All") return folder;
    else if (filterFolderValue === "Show Yes only")
      return (
        folder.groupStatus === "ENABLED" ||
        folder.inheritedGroupStatus === "ENABLED"
      );
    else if (filterFolderValue === "Show No only")
      return (
        folder.groupStatus === "DISABLED" ||
        folder.inheritedGroupStatus === "DISABLED"
      );
    else if (filterFolderValue === "Show Dynamic only")
      return (
        folder.groupStatus === "DYNAMIC" ||
        folder.inheritedGroupStatus === "DYNAMIC"
      );
  });
  console.log(folders, "folders");
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={() =>
          setSnackbarSettings({ ...snackbarSettings, open: false })
        }
        message="You can't make this resource inherited"
        key={vertical + horizontal}
      >
        <MuiAlert
          sx={{ fontSize: "18px", fontWeight: "500" }}
          severity="warning"
        >
          You can't make this resource inherited
        </MuiAlert>
      </Snackbar>
      {folders && folders.length ? (
        <TableContainer component={Paper}>
          <Box display={"flex"} alignItems={"center"} padding={"15px"}>
            <RadioAuthFilter
              handleFilterResourceValue={handleFilterResourceValue}
            />
          </Box>
          <Table
            sx={{
              borderCollapse: "collapse",
              borderStyle: "hidden",
              width: "100%",
              height: "fit-content",
              "& td, & th": {
                border: "1px solid #233044",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell className="userName">Folders</TableCell>
                <TableCell className="userName">Privilege Level</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFolders?.slice(0, showCount).map((folder) => (
                <TableRow className="groupRow" key={`${folder.folderId}`}>
                  <TableCell
                    component="td"
                    scope="row"
                    className="groupRowName userName"
                    title={folder.path}
                  >
                    {folder.path}
                  </TableCell>
                  <TableCell>
                    <Stack direction={"row"} spacing={3}>
                      <Select
                        sx={{ minWidth: "100px" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={folder.groupStatus ? folder.groupStatus : ""}
                        size="small"
                        onChange={(event) =>
                          addFolderToUser(folder, event.target.value)
                        }
                      >
                        <MenuItem value={"ENABLED"}>YES</MenuItem>
                        <MenuItem value={"DISABLED"}>NO</MenuItem>
                        <MenuItem value={"DYNAMIC"}>DYNAMIC</MenuItem>
                        <MenuItem value={"INHERITED"}>
                          INHERITED{" "}
                          {folder.inheritedGroupStatus === "ENABLED"
                            ? "(YES)"
                            : folder.inheritedGroupStatus === "DISABLED"
                            ? "(NO)"
                            : "(DYNAMIC)"}
                        </MenuItem>
                      </Select>
                      {folder.groupStatus === "DYNAMIC" &&
                      folder.remainingTime !== 0 ? (
                        <Stack spacing={1} sx={{ width: 150 }}>
                          <Timer minutes={folder.remainingTime} />
                        </Stack>
                      ) : null}
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
              {folders?.length > showCount ? (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <Button
                      variant={"text"}
                      onClick={() =>
                        setShowCount((prevCount) => prevCount + 50)
                      }
                    >
                      Load more...
                    </Button>
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : folders === undefined ? (
        <Box display={"flex"} p={5}>
          <CircularProgress />
        </Box>
      ) : (
        <Box pl={5}>
          <p style={{ fontSize: "20px", fontWeight: "500" }}>
            There are no any folders.
          </p>
        </Box>
      )}
    </>
  );
};

export default DataAccess;
