import React from 'react'
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

const RadioAuthFilter = ({ handleFilterResourceValue, filterName }) => {
  return (
    <FormControl sx={{ minWidth: "540px", paddingLeft: "10px" }}>
      <FormLabel id="demo-radio-buttons-group-label">{filterName}</FormLabel>
      <RadioGroup
        sx={{ display: "flex", flexDirection: "row" }}
        defaultValue={"Show All"}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <FormControlLabel
          value="Show All"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show All"
        />
        <FormControlLabel
          value="Show Yes only"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show Yes only"
        />
        <FormControlLabel
          value="Show No only"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show No only"
        />
        <FormControlLabel
          value="Show Dynamic only"
          control={<Radio onChange={handleFilterResourceValue} />}
          label="Show Dynamic only"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioAuthFilter